import BlogPostTemplate from '../../components/BlogPostTemplate'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query {
    markdownRemark(fields: { slug: { regex: "/weekly-project-update-1/" } }) {
      html
      frontmatter {
        title
        date
        readingTime
        author
        email
        excerpt
        tags
      }
    }
  }
`

export default BlogPostTemplate
