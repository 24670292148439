import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

const BlogPostTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const { title, date, readingTime, author, email } = frontmatter

  return (
    <>
      <Helmet>
        <title>{title} | Benjamin Tinsley</title>
        <meta name="description" content={frontmatter.description || title} />
      </Helmet>

      <main className="font-body">
        <div className="bg-indigo-800 text-yellow-100">
          <div className="container px-8 mx-auto py-16">
            <Link to="/blog" className="text-yellow-100 hover:text-yellow-200 mb-8 inline-block">
              ← Back to Blog
            </Link>
            <h1 className="font-display text-4xl md:text-5xl mb-4">{title}</h1>
            <div className="text-l tracking-wide">
              <time dateTime={date} className="block mb-2">
                {new Date(date).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </time>
              <span className="block mb-2">
                {readingTime} min read • {author}
              </span>
            </div>
          </div>
        </div>

        <div className="bg-orange-100">
          <div className="container px-8 mx-auto py-16">
            <div className="blog-post-content prose prose-lg max-w-3xl mx-auto">
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>

            <footer className="max-w-3xl mx-auto mt-16 pt-8 border-t border-gray-300">
              <div className="text-xl tracking-wide">
                {email && (
                  <p>
                    Have questions or feedback?{' '}
                    <a
                      href={`mailto:${email}`}
                      className="text-indigo-800 hover:text-indigo-600 underline"
                    >
                      Get in touch
                    </a>
                  </p>
                )}
              </div>
            </footer>
          </div>
        </div>
      </main>
    </>
  )
}

export default BlogPostTemplate
