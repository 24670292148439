import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/blog/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          date
          readingTime
          author
          excerpt
        }
        fileAbsolutePath
      }
    }
  }
`

function generateBlogPosts({ posts }) {
  console.log('Posts received:', posts)

  if (!posts || posts.length === 0) {
    console.log('No posts found')
    return null
  }

  return posts.map(post => {
    console.log('Processing post:', post)
    const urlPath = post.fileAbsolutePath.split('/content/blog/')[1].replace(/\.md$/, '')
    const frontmatter = post.frontmatter || {}

    return (
      <li key={urlPath} className="w-full mb-8 md:mb-16">
        <a
          href={`/blog/${urlPath}`}
          className={`block w-full pb-4 border-black border-b-2 md:hover:border-b-8 ease-out duration-300 transition-all`}
        >
          <time dateTime={frontmatter.date} className="mb-2 opacity-50">
            {new Date(frontmatter.date).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </time>
          <h2 className="text-2xl md:text-3xl mb-2">{frontmatter.title}</h2>
          {frontmatter.excerpt && <p className="mb-2">{frontmatter.excerpt}</p>}
          {/* {frontmatter.tags && frontmatter.tags.length > 0 && (
            <p className="mb-2">
              {frontmatter.tags.join(', ')}
            </p>
          )} */}
        </a>
      </li>
    )
  })
}

export default function BlogIndex({ data }) {
  const posts = data.allMarkdownRemark.nodes

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta charSet="utf-8" />
        <title>Blog | Benjamin Tinsley</title>
        <meta name="description" content="Blog posts by Benjamin Tinsley" />
        <link rel="canonical" href="https://bentinsley.dev/blog" />
      </Helmet>

      <main>
        <div className="bg-indigo-800 font-body">
          <div className="container px-8 mx-auto py-16">
            <Link to="/" className="text-yellow-100 hover:text-yellow-200 mb-8 inline-block">
              ← Back to Home
            </Link>
            <h1 className="font-display text-4xl md:text-6xl text-yellow-100 mb-4">Blog</h1>
          </div>
        </div>

        <div className="bg-orange-100 font-body">
          <div className="container px-8 mx-auto">
            <div className="pt-16 md:pt-24 pb-32">
              <ul className="max-w-xl mx-auto">{generateBlogPosts({ posts })}</ul>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
